$(document).ready(function() {
    var owlSlider2 = $('.owl-slider-green');

    owlSlider2.owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        navText: ['<i class="icon icon-arrow"><i>','<i class="icon icon-arrow"><i>'],
        items: 1,
        autoplay:true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayTimeout:5000
    });

    var owlSlider = $('.owl-slider');

    owlSlider.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="icon icon-arrow"><i>','<i class="icon icon-arrow"><i>'],
        items: 1,
        autoplay:true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayTimeout:5000
    });

    $('.datepicker').datepicker({
        language: 'cs-CZ',
        autoHide: true,
        format: 'dd.mm.yyyy'
    });


    $("#head .mobileNav").click(function(){
        if($('#head').hasClass('active')){
            $(this).find('i').addClass('icon-bars').removeClass('icon-cross');
            $('#head').removeClass('active');
            $('body').removeClass('locked');
        }else{
            toGo = $(window).scrollTop();
            $(this).find('i').removeClass('icon-bars').addClass('icon-cross');
            $('#head').addClass('active');
            $('body').addClass('locked');
        }
    });

    $('.modal-btn').click(function(){
        val  = $(this).attr('data-id');
        if(val != ""){
            $('#car-hidden').attr('value',val);
        }
        $('#'+$(this).attr('data-target')).addClass('active');
        //$('#'+$(this).attr('data-target')+' .modal-body').css('margin-top',($(document).scrollTop()+50));
        //$('body').addClass('locked');
    });

    $(".modal .close,.modal a.close-modal").click(function() {
        $(this).closest('.modal').removeClass('active');
        $('body').removeClass('locked');
    });


    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $(function () {
        $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
    } );
});

function goEntry(){
    var cookie_agreement = "agreement";
	var domain = document.domain.match(/[a-zA-Z0-9\-\_]+\.[a-zA-Z]{2,4}$/);
	if(!domain) domain = document.domain;
	document.cookie = cookie_agreement+"=true;;path=/;domain=."+domain;
	$('.cookies').removeClass('active');
}
